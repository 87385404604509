<template>
    <div style="background-color: #f7f7f7">
      <headers></headers>
      <div class="main1230" :style="{ height: highr + 'px',marginBottom:'0px'}">
        <div class="vr_hear">
            <div :class="shows==1?'vr_btn vr_color':'vr_btn'" @click="shows=1">“江苏·2021 丹青妙笔绘田园乡村”线上作品展</div>
            <div :class="shows==2?'vr_btn vr_color':'vr_btn'" @click="shows=2">“江苏·2022 丹青妙笔绘田园乡村”线上作品展</div>
            <div class="vr_btn_disable">第三届敬请期待</div>
        </div>
        <div class="vr_mian" :style="{ height: highr-94 + 'px'}">

            <img src="@/assets/img/pc1@2x.png" v-if="shows==1" @click="go('https://www.720yun.com/t/84vkzbrq7d9?scene_id=86396430')">
            <img src="@/assets/img/pc2@2x.png" v-if="shows==2" @click="go('https://www.720yun.com/t/94vk67iqg2l?scene_id=104839824')">
        </div>
      </div>
      <div id="modal1653222159963" class="modal fade in" role="dialog" aria-labelledby="modalLabel"
            style="z-index: 2147483647; display: block; padding-right: 17px;" v-show="deng">
            <div class="modal-dialog " style="width: 200px;">
                <div class="modal-content" style="border-radius: 20px;">
                    <div class="modal-header" style="display: none;"><button type="button" class="close"
                            data-关于举办第三届dismiss="modal"><span aria-hidden="true">×</span><span
                                class="sr-only">Close</span></button>
                        <h4 class="modal-title" id="modalLabel">提示</h4>
                    </div>
                    <div class="modal-body">
                        <div class="text-xxl text-center margin-top"><img src="@/assets/img/tip.jpg" class="tshi"></div>
                        <p class="text-xl text-center margin-top"
                            style="color:#000000; text-align: center;text-indent: 0px;">该功能暂未开放!</p>
                        <div class="text-xl text-center margin-top margin-bottom"><button
                                class="cu-btn round  bg-gradual-green btn ok" data-dismiss="modal" style="background-image: linear-gradient(45deg, #72b588, #6e9c7d);border-radius: 16px;" @click="masks">我知道了</button></div>
                    </div>
                </div>
            </div>
      </div>
      <div class="modal-backdrop fade in" v-show="deng"></div>
      <footers></footers>
    </div>
  </template>
    <script>
  import headers from "@/components/headers.vue";
  import footers from "@/components/footers.vue";
  export default {
    name: "appraise",
    data() {
      return {
        highr: document.body.clientHeight - 130,
        shows: 1,
        deng:false,
      };
    },
    components: {
      headers,
      footers,
    },

    mounted() {
    },

    methods: {
        mask(){
            this.deng=true
        },
        masks(){
            this.deng=false
        },
        go(e) {
            window.open(e,'_blank')
        }
    },
  };
  </script>
    <style>
  .bottomb {
    width: 100%;
    height: 30px;
    background: #fff;
    line-height: 30px;
    text-align: center;
  }
  .appraise_hear {
    margin: auto;
    height: 65px;
    border-bottom: #efefef solid 1px;
    position: relative;
  }
  .appraise_hear img {
    width: 257px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15.5px;
    margin-left: -128.5px;
  }
  .main1230 {
    width: 1200px;
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    position: relative;
  }
  .vr_hear{
    height: 74px;
    margin: 0 24px;
    border-bottom: 1px solid rgba(220, 220, 220, 1);
  }
  .vr_btn{
    width: 390px;
    height: 42px;
    line-height: 42px;
    background: #81BAAB;
    border-radius: 6px;
    /* text-align: center; */
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FEFEFE;
    margin: 16px 20px 0 0;
    display: inline-block;
    cursor: pointer;
  }
  .vr_btn_disable{
    height: 42px;
    line-height: 42px;
    background: #81BAAB;
    border-radius: 6px;
    /* text-align: center; */
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FEFEFE;
    margin: 16px 20px 0 0;
    display: inline-block;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
  }
  .vr_color{
      background: #F7C543;
  }
  .vr_mian{
    width: 100%;
    margin-top: 19px;
  }
  .vr_mian img{
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  </style>
